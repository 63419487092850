import ClayLoadingIndicator from "@clayui/loading-indicator";
import BookAnAppointment from "./BookAnAppointment";
import { useEffect, useState } from "react";
import { showError } from "../utils/util";
import { request } from "../utils/request";
import Appointment from "./Appointment";

const DoctorAvailability = ({ classPK, dataURL, dataAPIKey }) => {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const LoadDataRequest = () => {
      const config = {
        method: "GET",
        url: `/o/c/doctors/${classPK}`,
      };
      return request(config);
    };
    const loadData = async () => {
      try {
        setLoading(true);
        let data = await LoadDataRequest();
        setStatus(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError(error.message);
      }
    };
  
    useEffect(() => {
      loadData();
    }, []);
  
    if (loading) {
      return (
        <ClayLoadingIndicator displayType="primary" shape="squares" size="md" />
      );
    }
  
   
    if (status.doctorAvailability > 0) {
      return <BookAnAppointment doctorId={classPK} dataURL={dataURL} dataAPIKey={dataAPIKey}/>;
    }
  
    if (status.noOutpatientClinic) {
      return null; // Display nothing
    }
  
    return <Appointment doctorName={status.name} doctorId={status.id} />;
  };
  export default DoctorAvailability;