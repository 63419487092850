/* global Liferay */

import { useEffect, useState } from "react";
import { request } from "../utils/request";
import { showError } from "../utils/util";
import ClayLoadingIndicator from "@clayui/loading-indicator";

const BookAnAppointment = ({ doctorId, dataURL ,dataAPIKey }) => {

	const [loading, setLoading] = useState(false);
	const [providerId, setProviderId] = useState(null);

	const LoadDataRequest = () => {

		const config = {
			method: 'GET',
			url: `/o/c/doctors/${doctorId}`
		}

		return request(config);

	}
	const loadData = async () => {


		try {
			setLoading(true);

			let data = await LoadDataRequest();

			setProviderId(data.providerId);

			setLoading(false);

		} catch (error) {

			setLoading(false);

			showError(error.message);

		}

	}

	useEffect(() => {

		loadData();

	}, [])


	if (providerId === null) {
		return <ClayLoadingIndicator displayType="primary" shape="squares" size="md" />;
	}

	return (
		<div className="dhBookAppointment">
			<div className="bookAppointRatioFiled ratioBorderClr1">
				<div className="container-fluid containerFluid">
					<div className="commonHeadingOne">
						<h2>{Liferay.Language.get('book-appointment')}</h2>
					</div>
					<div
						id="schedulingId"
						data-url={dataURL}
						data-apikey={dataAPIKey}
						data-widget-type="openscheduling"
						data-additionalparams-dept=""
						data-additionalparams-vt=""
						data-additionalparams-id={providerId}
					></div>
				</div>
			</div>
		</div>
	);
};

export default BookAnAppointment;
