import React from "react";
import { createRoot } from "react-dom/client";
import SpecialtiesListing from "./components/SpecialtiesListing";
import { getLiferayThemeSpritemap } from "./utils/util";
import { ClayIconSpriteContext } from "@clayui/icon";
import DoctorAvailability from "./components/DoctorAvailability";

const ELEMENT_ID_Specialties_Listing =
	"dha-front-end-components-specialties-listing";
const ELEMENT_ID_BOOK_AN_APPOINTMENT =
	"dha-front-end-components-book-an-appointment";

class SpecialtiesListingComponent extends HTMLElement {
	connectedCallback() {
		this.root = createRoot(this);
		console.log("parent"+this.getAttribute("parent"))
		this.root.render(
			<ClayIconSpriteContext.Provider value={getLiferayThemeSpritemap()}>
				<SpecialtiesListing
					displayPageUrlPrefix={this.getAttribute("display-page-url-prefix")}
					parentType={this.getAttribute("parent-type")}
					classPK={this.getAttribute("parent")}
				/>
			</ClayIconSpriteContext.Provider>,
			this
		);
	}

	disconnectedCallback() {
		this.root.unmount();

		delete this.root;
	}
}

class DoctorAvailabilityComponent extends HTMLElement {
	connectedCallback() {
		this.root = createRoot(this);
		this.root.render(
			<DoctorAvailability classPK={this.getAttribute("parent")} dataURL={this.getAttribute("data-url")} dataAPIKey={this.getAttribute("data-api-key")} />
		);
	}

	disconnectedCallback() {
		this.root.unmount();
		delete this.root;
	}
}

if (!customElements.get(ELEMENT_ID_Specialties_Listing)) {
	customElements.define(
		ELEMENT_ID_Specialties_Listing,
		SpecialtiesListingComponent
	);
}

if (!customElements.get(ELEMENT_ID_BOOK_AN_APPOINTMENT)) {
	customElements.define(
		ELEMENT_ID_BOOK_AN_APPOINTMENT,
		DoctorAvailabilityComponent
	);
}
