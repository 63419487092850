/* global Liferay */

import { useState } from "react";
import RequestAppointmentFrom from "./RequestAppointmentForm";

const Appointment = ({ doctorName, doctorId }) => {
  const [appointmentType, setAppointmentType] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className="requestAppointmentTab Faq_Main_Wraper">
      <div className="container containerFluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="requestAppointmentTabInner tab_inner_wrapper">
              {isSubmitted ? (
                <div className="success-message loginTab">
                  <div>
                      <div class="bg-white font-weight-semi-bold p-5 text-3 text-center text-secondary">
                        {Liferay.Language.get(
                          "request-appointment-success-message"
                        )}
                      </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="requestAppointmentHead">
                    <h5>
                      {Liferay.Language.get(
                        "who-is-the-appointment-requested-for"
                      )}
                    </h5>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            appointmentType === 0 ? "active" : ""
                          }`}
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#myself"
                          role="tab"
                          aria-controls="home"
                          aria-selected={
                            appointmentType === 0 ? "true" : "false"
                          }
                          onClick={() => setAppointmentType(0)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99298 8.79789C8.95911 8.79789 10.5582 7.19803 10.5582 5.23191C10.5582 3.26578 8.95837 1.66666 6.99298 1.66666C5.02759 1.66666 3.42773 3.26651 3.42773 5.23191C3.42773 7.1973 5.02759 8.79789 6.99298 8.79789ZM6.99298 2.84604C8.30861 2.84604 9.37959 3.91627 9.37959 5.23264C9.37959 6.54901 8.30935 7.61924 6.99298 7.61924C5.67661 7.61924 4.60639 6.54901 4.60639 5.23264C4.60639 3.91627 5.67661 2.84604 6.99298 2.84604Z"
                              fill="white"
                            />
                            <path
                              d="M16.5328 8.20554V10.2361C16.5328 10.7636 16.0054 11.1921 15.3578 11.1921H15.2933C14.6458 11.1921 14.1183 10.7636 14.1183 10.2361V8.20554H12.95V10.2361C12.95 11.2243 13.6979 12.0565 14.7073 12.2931C14.5132 13.3589 13.5602 14.1706 12.4159 14.1706H12.3434V13.1523C12.3405 10.1043 9.40448 9.99512 9.40375 9.99512C9.40375 9.99512 11.1142 10.954 11.1596 13.2732C11.1596 13.2959 11.1604 13.6541 11.1611 14.1706H8.02878C7.8244 13.808 7.4369 13.5633 6.99225 13.5633C6.33517 13.5633 5.80188 14.0966 5.80188 14.7537C5.80188 15.4108 6.33517 15.944 6.99225 15.944C7.4369 15.944 7.8244 15.6994 8.02878 15.3382H11.1618C11.1626 16.7381 11.1633 18.3328 11.1633 18.3328H12.3434V15.339H12.4152C14.2011 15.339 15.6779 14.0116 15.886 12.3048C16.9247 12.0865 17.7012 11.2419 17.7012 10.2354V8.2048H16.5328V8.20554Z"
                              fill="white"
                            />
                            <path
                              d="M1.66669 13.1523V18.3335H2.84681C2.84681 18.3335 2.84828 13.3588 2.85048 13.2739C2.89589 10.9546 4.60636 9.99576 4.60636 9.99576C4.60636 9.99576 1.66962 10.1049 1.66669 13.153V13.1523Z"
                              fill="white"
                            />
                          </svg>
                          {Liferay.Language.get("myself")}
                        </a>
                      </li>
                      <li
                        className={`nav-item  ${
                          appointmentType === 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className={`nav-link ${
                            appointmentType === 1 ? "active" : ""
                          }`}
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#someoneElse"
                          role="tab"
                          aria-controls="profile"
                          aria-selected={
                            appointmentType === 1 ? "true" : "false"
                          }
                          onClick={() => setAppointmentType(1)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2767 12.1182C10.0672 11.6467 9.85042 11.1591 9.63364 10.6811C8.80668 8.84977 6.9294 7.88193 4.96307 8.2717C3.94706 8.47315 3.01791 9.08187 2.41429 9.94169C1.7771 10.8489 1.53551 11.9642 1.73477 13.0817C1.88585 13.932 2.28802 14.686 2.9296 15.3239C3.29089 15.6823 3.46096 16.1012 3.45147 16.6041C3.44709 16.8224 3.44782 17.0413 3.44855 17.2523C3.44855 17.342 3.44928 17.4326 3.44928 17.5223V18.2632H8.19576V17.3793C9.07163 17.318 9.79422 16.6778 9.93655 15.8188C9.97231 15.6013 9.97304 15.3903 9.97377 15.2035C9.97377 15.1341 9.97378 15.0677 9.97597 15.0035C10.1833 14.7896 10.4146 14.559 10.6949 14.2853C10.9387 14.0473 11 13.7349 10.8621 13.4262C10.6657 12.9912 10.4716 12.5547 10.2774 12.1182H10.2767ZM9.05849 14.2721L9.05338 14.2772C8.9147 14.4247 8.82565 14.6378 8.81325 14.8444C8.80522 14.9684 8.80522 15.086 8.80449 15.1998C8.80449 15.356 8.80376 15.5042 8.78332 15.6275C8.72712 15.9647 8.42494 16.2122 8.06365 16.2158C7.96949 16.2166 7.87461 16.2158 7.77534 16.2158H7.02647V17.0946H4.61418C4.61418 16.9362 4.61419 16.7822 4.6171 16.6275C4.63389 15.7998 4.34267 15.0823 3.75146 14.4947C3.27703 14.0232 2.9931 13.4941 2.88361 12.8766C2.561 11.0613 3.80254 9.69206 5.18934 9.41689C6.63379 9.13005 7.96074 9.81542 8.56873 11.1613C8.78405 11.6372 9.0001 12.1226 9.20885 12.5919C9.36505 12.943 9.52124 13.2948 9.67817 13.6444C9.44387 13.8758 9.24315 14.0787 9.05776 14.2707L9.05849 14.2721Z"
                              fill="#002F70"
                            />
                            <path
                              d="M17.5836 3.43952C16.9785 2.57825 16.0479 1.96806 15.0297 1.76588C13.059 1.37539 11.1788 2.34542 10.3504 4.17963C10.1336 4.6599 9.91609 5.1482 9.70588 5.62044C9.51173 6.05764 9.31685 6.49485 9.11905 6.93351C8.98183 7.2408 9.04314 7.55319 9.28692 7.79113C9.56793 8.06557 9.80004 8.29695 10.0081 8.51154C10.0102 8.57577 10.0102 8.64292 10.0102 8.71226C10.0102 8.89911 10.0117 9.11078 10.0475 9.32901C10.1905 10.1896 10.9153 10.8304 11.7926 10.8917V11.7771H16.5471V11.0355C16.5471 10.9457 16.5471 10.8552 16.5479 10.7654C16.5486 10.553 16.5501 10.3341 16.545 10.1158C16.5347 9.61148 16.7055 9.19106 17.0683 8.83123C17.7106 8.19257 18.1135 7.43641 18.2653 6.58535C18.4646 5.4657 18.223 4.34824 17.5843 3.43952H17.5836ZM17.115 6.38098C17.0048 6.99993 16.7201 7.53056 16.245 8.00353C15.6523 8.59182 15.3603 9.31077 15.3771 10.1407C15.3801 10.2961 15.3808 10.4501 15.3801 10.61H12.9597V9.72899H12.2101C12.1109 9.72899 12.016 9.72972 11.9211 9.72899C11.5591 9.72534 11.2547 9.47645 11.1985 9.13851C11.1781 9.0137 11.1773 8.86553 11.1766 8.70861C11.1766 8.59401 11.1759 8.4765 11.1686 8.35534C11.1569 8.14586 11.0671 7.932 10.9284 7.78456L10.9233 7.77873C10.7379 7.5853 10.5365 7.38239 10.3007 7.15029C10.4584 6.79848 10.6153 6.44667 10.7715 6.09487C10.981 5.62409 11.197 5.13725 11.4131 4.66063C12.0226 3.31106 13.3524 2.62424 14.8013 2.91181C16.1924 3.18771 17.4376 4.56137 17.1135 6.38098H17.115Z"
                              fill="#002F70"
                            />
                            <path
                              d="M13.8794 15.7846C13.8101 15.8226 10.9358 17.3093 10.9358 17.3093L11.4387 18.2713L14.4823 16.6984C16.9669 15.3978 15.8049 12.9563 15.8049 12.9563C15.8049 12.9563 15.7516 14.7598 13.8794 15.7853V15.7846Z"
                              fill="#002F70"
                            />
                            <path
                              d="M6.03092 4.15339C6.10026 4.11544 8.97456 2.62865 8.97456 2.62865L8.47168 1.66666L5.42803 3.23957C2.94348 4.54024 4.10546 6.98172 4.10546 6.98172C4.10546 6.98172 4.15875 5.17816 6.03092 4.15267V4.15339Z"
                              fill="#002F70"
                            />
                          </svg>
                          {Liferay.Language.get("someone-else")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="hrHroziLineOuter">
                    <hr className="hrHroziLine" />
                  </div>
                  <div className="requestAppointmentTabContent">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="myself"
                        role="tabpanel"
                        aria-labelledby="myself-tab"
                      >
                        <div className="requestAppointmentForm">
                          <div className="requestAppointmentFormInner">
                            <div className="requestAppointmentHead">
                              <h5>
                                {Liferay.Language.get("your-information")}
                              </h5>
                            </div>
                            <RequestAppointmentFrom
                              appointmentType={appointmentType}
                              doctorName={doctorName}
                              onSuccess={() => setIsSubmitted(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
