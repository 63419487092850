/* global Liferay */

import { request } from "./request";

export function showError(title, message) {
  Liferay.Util.openToast({ message, title, type: "danger" });
}

export function showSuccess(
  title,
  message = "The request has been successfully completed."
) {
  Liferay.Util.openToast({ message, title, type: "success" });
}

export function getLiferayThemeSpritemap() {
  return Liferay.Icons.spritemap;
}

export function getLiferaySelectedLanguage() {
  return Liferay.ThemeDisplay.getLanguageId().replace("-", "_");
}
export const getPickListData = async (ERC) => {
  const config = {
    method: "GET",
    url: `/o/headless-admin-list-type/v1.0/list-type-definitions/by-external-reference-code/${ERC}/list-type-entries`,
  };

  return await request(config);
};
export const getCountriesData = async() => {
  const config = {
    method: "GET",
    url: `/o/headless-admin-address/v1.0/countries?fields=a3,idd&pageSize=300`,
  };
  return await request(config);
};
