/* global Liferay */
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { mySelfValidationSchema, someOnceValidationSchema } from "../schema";
import { getCountriesData, getPickListData } from "../utils/util";
import {
  loadCaptchaEnginge,
  validateCaptcha,
  LoadCanvasTemplateNoReload,
} from "react-simple-captcha";
import axios from "axios";

export const HEADERS = {
  "x-csrf-token": Liferay.authToken,
  "Accept-Language": Liferay.ThemeDisplay.getLanguageId().replace("_", "-"),
  "Content-Type": "application/json",
};
const loadCaptchaAgain = () => {
  loadCaptchaEnginge(6, "#808080", "#000000");
};

const RequestAppointmentFrom = ({ appointmentType, doctorName , onSuccess}) => {
  const [callbackTimeValues, setCallbackTimeValues] = useState([]);
  const [countriesValues, setCountriesValues] = useState([]);
  const [inValidCaptcha, setInValidCaptcha] = useState(false);
  const handleCaptchaResetMsg = (e) => {
    setInValidCaptcha(false);
  };
  const validateSimpleCaptcha = (captchaText) => {
    if (validateCaptcha(captchaText) === true) {
      loadCaptchaEnginge(6, "#808080", "#000000");
      return true;
    } else {
      loadCaptchaEnginge(6, "#808080", "#000000");
      setInValidCaptcha(true);
      return false;
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    firstTime: "true",
    preferredCallbackTime: "",
    insured: "true",
    reasonForVisit: "",
    patientFirstName: "",
    countryCode: 971,
    patientCountryCode: 971,
    patientLastName: "",
    patientDateOfBirth: "",
    doctorName: doctorName,
    captchaText: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (validateSimpleCaptcha(values.captchaText)) {
      setSubmitting(true);
      const scopeKey = Liferay?.ThemeDisplay.getScopeGroupId();
      let patientPhoneNumber;
      if (values.patientPhoneNumber) {
        patientPhoneNumber =
          "+" + values.patientCountryCode + values.patientPhoneNumber;
      }
      const updatedValues = {
        ...values,
        mySelf: appointmentType === 0,
        phoneNumber: "+" + values.countryCode + values.phoneNumber,
        patientPhoneNumber: patientPhoneNumber,
      };
      axios
        .post(`/o/c/requestappointments/scopes/${scopeKey}`, updatedValues, {
          headers: HEADERS,
        })
        .then((response) => {
          onSuccess();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (!callbackTimeValues.length) {
      getPickListData("REQUESTAPPOINTTIME").then((data) => {
        data.items && setCallbackTimeValues(data.items);
      });
    }
    if (!countriesValues.length) {
      getCountriesData().then((data) => {
        data.items && setCountriesValues(data.items);
      });
    }
    loadCaptchaEnginge(6, "#808080", "#000000");
  }, []);

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        appointmentType === 0
          ? mySelfValidationSchema
          : someOnceValidationSchema
      }
    >
      {({ handleChange, setSubmitting, isSubmitting }) => (
        <Form>
          <div class="row">
            <div class="col-md-6">
              <div class="input-field">
                <label for="firstName">
                  {Liferay.Language.get("first-name")}
                </label>
                <Field
                  type="text"
                  name="firstName"
                  placeholder={Liferay.Language.get("first-name-placeholder")}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-field">
                <label for="lastName">
                  {Liferay.Language.get("last-name")}
                </label>
                <Field
                  type="text"
                  name="lastName"
                  placeholder={Liferay.Language.get("last-name-placeholder")}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {appointmentType === 0 && (
              <div class="col-md-6">
                <div class="input-field">
                  <label for="email">
                    {Liferay.Language.get("email-address")}
                  </label>
                  <Field
                    type="email"
                    placeholder={Liferay.Language.get(
                      "email-address-placeholder"
                    )}
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            )}
            <div class="col-md-6">
              <div class="input-field">
                <label for="phoneNumber">
                  {Liferay.Language.get("phone-number")}
                </label>
                <div class="selectInputField selectBold">
                  <Field
                    as="select"
                    className="form-control"
                    name="countryCode"
                    defaultValue={initialValues.countryCode}
                    onChange={handleChange}
                  >
                    {countriesValues.map((item) => (
                      <option key={item.idd} value={item.idd}>
                        {item.a3}
                      </option>
                    ))}
                  </Field>
                  <Field
                    type="number"
                    placeholder={Liferay.Language.get(
                      "phone-number-placeholder"
                    )}
                    name="phoneNumber"
                  />
                </div>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {appointmentType === 0 && (
              <div class="col-md-6">
                <div class="input-field">
                  <label for="dateOfBirth">
                    {Liferay.Language.get("date-of-birth")}{" "}
                  </label>
                  <Field
                    type="date"
                    placeholder={Liferay.Language.get("dob-placeholder")}
                    name="dateOfBirth"
                  />
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            )}
          </div>

          {appointmentType === 1 && (
            <>
              <div class="hrHroziLineOuter">
                <hr class="hrHroziLine" />
              </div>
              <div class="requestAppointmentHead">
                <h5>{Liferay.Language.get("patient-information")}</h5>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-field">
                    <label for="patientFirstName">
                      {Liferay.Language.get("patient-first-name")}
                    </label>
                    <Field
                      type="text"
                      placeholder={Liferay.Language.get(
                        "patient-first-name-placeholder"
                      )}
                      name="patientFirstName"
                    />
                    <ErrorMessage
                      name="patientFirstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-field">
                    <label for="patientLastName">
                      {Liferay.Language.get("patient-last-name")}
                    </label>
                    <Field
                      type="text"
                      placeholder={Liferay.Language.get(
                        "patient-last-name-placeholder"
                      )}
                      name="patientLastName"
                    />
                    <ErrorMessage
                      name="patientLastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-field">
                    <label for="patientEmail">
                      {Liferay.Language.get("patient-email")}
                    </label>
                    <Field
                      type="email"
                      placeholder={Liferay.Language.get(
                        "patient-email-address-placeholder"
                      )}
                      name="patientEmail"
                    />
                    <ErrorMessage
                      name="patientEmail"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-field">
                    <label for="patientPhoneNumber">
                      {Liferay.Language.get("patient-phone-number")}
                    </label>
                    <div class="selectInputField selectBold">
                      <Field
                        as="select"
                        className="form-control"
                        name="patientCountryCode"
                        defaultValue={initialValues.patientCountryCode}
                        onChange={handleChange}
                      >
                        {countriesValues.map((item) => (
                          <option key={item.idd} value={item.idd}>
                            {item.a3}
                          </option>
                        ))}
                      </Field>
                      <Field
                        type="number"
                        placeholder={Liferay.Language.get(
                          "phone-number-placeholder"
                        )}
                        name="patientPhoneNumber"
                      />
                    </div>
                    <ErrorMessage
                      name="patientPhoneNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-field">
                    <label for="patientDateOfBirth">
                      {Liferay.Language.get("patient-date-of-birth")}
                    </label>
                    <Field
                      type="date"
                      placeholder={Liferay.Language.get("dob-placeholder")}
                      name="patientDateOfBirth"
                    />
                    <ErrorMessage
                      name="patientDateOfBirth"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="hrHroziLineOuter">
            <hr class="hrHroziLine" />
          </div>
          <div class="requestAppointmentHead">
            <h5>{Liferay.Language.get("appointment-information")}</h5>
          </div>

          <div class="row">
            <div className="col-md-6">
              <div className="input-field">
                <label htmlFor="firstTime">
                  {Liferay.Language.get(
                    "is-this-your-first-time-visiting-with"
                  )}{" "}
                  {doctorName}?
                </label>
                <div className="ratioGroup">
                  <div className="ratioButton nxtClr">
                    <Field
                      type="radio"
                      name="firstTime"
                      id="firstTimeYes"
                      value="true"
                      className="form-check-input"
                    />
                    <label className="btn btn-default" htmlFor="firstTimeYes">
                      {Liferay.Language.get("yes")}
                    </label>
                  </div>
                  <div className="ratioButton nxtClr">
                    <Field
                      type="radio"
                      name="firstTime"
                      id="firstTimeNo"
                      value="false"
                      className="form-check-input"
                    />
                    <label className="btn btn-default" htmlFor="firstTimeNo">
                      {Liferay.Language.get("no")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="input-field">
                <label for="message">
                  {Liferay.Language.get("preferred-callback-time")}
                </label>
                <div class="customSelect">
                  <select
                    id="mySelect"
                    name="preferredCallbackTime"
                    class="custom-select"
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      {Liferay.Language.get("select-preferred-callback-time")}
                    </option>
                    {callbackTimeValues.map((item) => {
                      return (
                        <option key={item.key} value={item.key}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <ErrorMessage
                    name="preferredCallbackTime"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-field">
                <label htmlFor="reasonForVisit">
                  {Liferay.Language.get("reason-for-the-visit")}
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder={Liferay.Language.get(
                    "reason-for-the-visit-placeholder"
                  )}
                  rows="5"
                  name="reasonForVisit"
                />
                <ErrorMessage
                  name="reasonForVisit"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div className="col-md-6">
              <div className="input-field mb-0">
                <label htmlFor="message">
                  {Liferay.Language.get("are-you-insured")}
                </label>
                <div className="ratioGroup">
                  <div className="ratioButton nxtClr">
                    <Field
                      type="radio"
                      id="insured1"
                      name="insured"
                      value="true"
                      className="form-check-input"
                    />
                    <label className="btn btn-default" htmlFor="insured1">
                      {Liferay.Language.get("yes")}
                    </label>
                  </div>
                  <div className="ratioButton nxtClr">
                    <Field
                      type="radio"
                      id="insured2"
                      name="insured"
                      value="false"
                      className="form-check-input"
                    />
                    <label className="btn btn-default" htmlFor="insured2">
                      {Liferay.Language.get("no")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hrHroziLineOuter">
            <hr class="hrHroziLine" />
          </div>

          <div class="row">
            <div class="col-md-6">
              <div className="d-flex">
                <div className="captcha-text">
                  <LoadCanvasTemplateNoReload
                    reloadColor="red"
                    reloadText="reload"
                  />
                </div>
                <div className="captcha-reload" onClick={loadCaptchaAgain}>
                  <svg
                    aria-hidden="true"
                    class="lexicon-icon lexicon-icon-reload"
                    focusable="false"
                  >
                    <use href="/o/classic-theme/images/clay/icons.svg#reload"></use>
                  </svg>
                </div>
              </div>
              <div className="input-field">
                <Field
                  name="captchaText"
                  type="text"
                  placeholder={Liferay.Language.get(
                    "type-your-word-label-placeholder"
                  )}
                  onClick={(event) => handleCaptchaResetMsg(event)}
                />
                <ErrorMessage
                  name="captchaText"
                  component="div"
                  className="text-danger"
                />
                {inValidCaptcha && (
                  <div class="text-danger">
                    {Liferay.Language.get("invalid-captcha-error-message")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="formBtnContainer">
                <button
                  type="submit"
                  class="btn btn1 formBtn"
                  disabled={isSubmitting}
                >
                  {Liferay.Language.get("submit")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestAppointmentFrom;
