import { useEffect, useState } from "react";
import { request } from "../utils/request";
import { getLiferaySelectedLanguage, showError } from "../utils/util";
import ClayLoadingIndicator from "@clayui/loading-indicator";
import ClayLayout from '@clayui/layout';


const LoadDataRequest = (parentId, parentType) => {

    const config = {
        method: 'GET',
        url: `/o/c/${parentType === "doctor" ? "doctors" : "departments"}/${parentId}/${parentType === "doctor" ? "doctorsToSpecialities" : "departmentToSpecialities"}?page=0`
    }

    return request(config);

}


const SpecialtiesListing = ({ classPK, parentType, displayPageUrlPrefix }) => {

    const [Specialties, setSpecialties] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadData = async () => {


        try {
            setLoading(true);

            let data = await LoadDataRequest(classPK, parentType);

            setSpecialties(data.items.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName)));

            setLoading(false);

        } catch (error) {

            setLoading(false);

            showError(error.message);

        }

    }

    useEffect(() => {

        loadData();

    }, [])

    return (<>
        {!loading && (
            <>
                <div className="dubaiHealthSpecialitiesGroup subSpecialties">
                    {Specialties && Specialties.length > 0 && (

                        <>
                            {Specialties.map((item, index) => (
                                <>
                                    <a href={`${displayPageUrlPrefix || ""}/l/${item.id}`}
                                        className="dubaiHealthSpecialitiesLink">
                                        <p>{item.specialtyName_i18n[getLiferaySelectedLanguage()] || item.specialtyName}</p>
                                        <img src="/o/dubai-health-theme-css/images/icon/vector.svg" alt="" />
                                    </a>
                                </>
                            ))}
                        </>

                    )}
                </div>
            </>
        )}

        {loading && (<ClayLoadingIndicator displayType="primary" shape="squares" size="md" />)}


    </>)


}

export default SpecialtiesListing;
