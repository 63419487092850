/* global Liferay */

import * as Yup from "yup";

export const mySelfValidationSchema = () =>
	Yup.object().shape({
		firstName: Yup.string()
			.required(Liferay.Language.get("first-name-required-error-message"))
			.max(50, Liferay.Language.get("first-name-50-characters-limit-error-message")),
		lastName: Yup.string()
			.required(Liferay.Language.get("last-name-required-error-message"))
			.max(50, Liferay.Language.get("last-name-50-characters-limit-error-message")),
		phoneNumber: Yup.string()
			.required(Liferay.Language.get("mobile-number-required-error-message"))
			.matches(/^[0-9]*$/, Liferay.Language.get("mobile-number-minimum-digit-validation-error-message"))
			.min(9, Liferay.Language.get("mobile-number-minimum-digit-validation"))
			.max(12, Liferay.Language.get("mobile-number-minimum-digit-validation")),
		firstTime: Yup.string()
			.required(Liferay.Language.get("first-visit-required-error-message"))
			.max(500, Liferay.Language.get("first-visit-characters-limit-error-message")),
		preferredCallbackTime: Yup.string()
			.required(Liferay.Language.get("preferred-callback-time-required-error-message")),
		email: Yup.string()
			.email(Liferay.Language.get("invalid-email-address-limit-error-message"))
			.required(Liferay.Language.get("email-address-required-error-message")),
		dateOfBirth: Yup.date()
		.required(Liferay.Language.get("birth-date-required-label"))
		.max(new Date(), Liferay.Language.get('date-of-birth-cannot-be-in-the-future-error-message')),
		reasonForVisit: Yup.string()
			.required(Liferay.Language.get("reason-required-label"))
			.max(500, Liferay.Language.get("less-then-500-characters-limit-error-message")),
		captchaText: Yup.string()
			.required(Liferay.Language.get("captcha-text-required-label"))
	
	});

export const someOnceValidationSchema = () =>
	Yup.object().shape({
		firstName: Yup.string()
			.required(Liferay.Language.get("first-name-required-error-message"))
			.max(50, Liferay.Language.get("first-name-50-characters-limit-error-message")),
		lastName: Yup.string()
			.required(Liferay.Language.get("last-name-required-error-message"))
			.max(50, Liferay.Language.get("last-name-50-characters-limit-error-message")),
		phoneNumber: Yup.string()
			.required(Liferay.Language.get("mobile-number-required-error-message"))
			.matches(/^[0-9]*$/, Liferay.Language.get("mobile-number-minimum-digit-validation"))
			.min(9, Liferay.Language.get("mobile-number-minimum-digit-validation"))
			.max(12, Liferay.Language.get("mobile-number-minimum-digit-validation")),

		firstTime: Yup.string()
			.required(Liferay.Language.get("first-visit-required-error-message"))
			.max(500, Liferay.Language.get("first-visit-characters-limit-error-message")),
		patientFirstName: Yup.string()
			.required(Liferay.Language.get("patient-first-name-required-error-message"))
			.max(50, Liferay.Language.get("patient-first-name-50-characters-limit-error-message")),
		patientLastName: Yup.string()
			.required(Liferay.Language.get("patient-last-name-required-error-message"))
			.max(50, Liferay.Language.get("patient-last-name-50-characters-limit-error-message")),
		patientDateOfBirth: Yup.date()
			.required('patient-birth-date-required-required-error-message')
			.max(new Date(), Liferay.Language.get('patient-birth-cannot-be-in-the-future-error-message')),
		preferredCallbackTime: Yup.string().required(Liferay.Language.get("preferred-callback-time-required-error-message")),
		reasonForVisit: Yup.string()
			.required(Liferay.Language.get("reason-required-label"))
			.max(500, Liferay.Language.get("less-then-500-characters-limit-error-message")),
		captchaText: Yup.string()
		.required(Liferay.Language.get("captcha-text-required-label"))
	});
